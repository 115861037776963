/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'building-fill-up': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M12.5 16a3.5 3.5 0 100-7 3.5 3.5 0 000 7m.354-5.854l1.5 1.5a.5.5 0 01-.708.708L13 11.707V14.5a.5.5 0 01-1 0v-2.793l-.646.647a.5.5 0 01-.708-.708l1.5-1.5a.5.5 0 01.708 0"/><path pid="1" d="M2 1a1 1 0 011-1h10a1 1 0 011 1v7.256A4.5 4.5 0 0012.5 8a4.5 4.5 0 00-3.59 1.787A.5.5 0 009 9.5v-1a.5.5 0 00-.5-.5h-1a.5.5 0 00-.5.5v1a.5.5 0 00.5.5h1a.5.5 0 00.39-.187A4.5 4.5 0 008.027 12H6.5a.5.5 0 00-.5.5V16H3a1 1 0 01-1-1zm2 1.5v1a.5.5 0 00.5.5h1a.5.5 0 00.5-.5v-1a.5.5 0 00-.5-.5h-1a.5.5 0 00-.5.5m3 0v1a.5.5 0 00.5.5h1a.5.5 0 00.5-.5v-1a.5.5 0 00-.5-.5h-1a.5.5 0 00-.5.5m3.5-.5a.5.5 0 00-.5.5v1a.5.5 0 00.5.5h1a.5.5 0 00.5-.5v-1a.5.5 0 00-.5-.5zM4 5.5v1a.5.5 0 00.5.5h1a.5.5 0 00.5-.5v-1a.5.5 0 00-.5-.5h-1a.5.5 0 00-.5.5M7.5 5a.5.5 0 00-.5.5v1a.5.5 0 00.5.5h1a.5.5 0 00.5-.5v-1a.5.5 0 00-.5-.5zm2.5.5v1a.5.5 0 00.5.5h1a.5.5 0 00.5-.5v-1a.5.5 0 00-.5-.5h-1a.5.5 0 00-.5.5M4.5 8a.5.5 0 00-.5.5v1a.5.5 0 00.5.5h1a.5.5 0 00.5-.5v-1a.5.5 0 00-.5-.5z"/>',
    },
});
